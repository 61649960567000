import type { WorkItem } from "jobber/workItems/types";
import { randomLineItemId } from "./utils";

export interface LineItem {
  // Normally a react key would be the ID, but in the case of new line items
  // there's no ID yet. Trying to kluge the ID to work for React complicates
  // things, so we use a separate reactKey for React and ID for Rails.
  reactKey: string;
  id: string;
  name: string;
  description: string;
  quantity: number;
  unitCost?: number;
  unitPrice?: number;
  taxable?: boolean;
  totalPrice?: number;
  totalCost?: number;
  quoteLineItemId?: number;
  overrideDates?: string[];
  // Indicates if an item is deleted. A deleted item needs to be sent to the DB
  // with a _destroyed marker to allow Rails to delete the line item.
  isDeleted: boolean;
  image?: {
    url?: string;
    thumbnailUrl?: string;
    fileName?: string;
    fileSize?: number;
    contentType?: string;
  };
}

export function newLineItem<T extends LineItem>(
  initialLineItem?: Partial<T>,
): T {
  return {
    reactKey: randomLineItemId(),
    id: "",
    name: "",
    description: "",
    quantity: 1,
    unitCost: 0,
    unitPrice: 0,
    totalPrice: 0,
    totalCost: 0,
    taxable: true,
    isDeleted: false,
    ...initialLineItem,
  } as T;
}

export function mergeProductOrService(
  lineItem: LineItem,
  item: WorkItem,
): LineItem {
  return {
    ...lineItem,
    name: item.name,
    description: item.description ?? "",
    unitCost: item.internalUnitCost ?? 0,
    unitPrice: item.defaultUnitCost,
    totalCost: lineItem.quantity * (item.internalUnitCost ?? 0),
    totalPrice: lineItem.quantity * item.defaultUnitCost,
    taxable: item.taxable ?? true,
  };
}

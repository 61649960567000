import React, { useContext } from "react";
import { MessageCenterContext } from "jobber/chat/components/MessageCenterButton";
import { UkKycPendingState } from "jobber/chat/components/UkKycRegistration/UkKycPendingState";
import { IntlProvider } from "@translations/IntlProvider";
import { UkKycApprovedState } from "jobber/chat/components/UkKycRegistration/UkKycApprovedState";
import { UkKycInitialState } from "jobber/chat/components/UkKycRegistration/UkKycInitialState";
import { UkKycRejectedState } from "jobber/chat/components/UkKycRegistration/UkKycRejectedState";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import type { UkKycRegistrationDetails } from "~/utilities/API/graphql";
import {
  UkKycRegistrationRequirementTypes,
  UkKycRegistrationStatuses,
} from "~/utilities/API/graphql";
import { useUkKycRegistrationUpdate } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration/hooks/useUkKycRegistrationUpdate/useUkKycRegistrationUpdate";

function UkKycRegistrationContent() {
  const [state, dispatch] = useContext(MessageCenterContext);
  const registration = state?.ukKycComplianceRegistrationData?.registration;
  const { ukKycRegistrationUpdate } = useUkKycRegistrationUpdate();
  async function handleAcknowledgedAt() {
    const payload = {
      input: {
        acknowledgedAt: new Date().toISOString(),
      },
    };

    const result = await ukKycRegistrationUpdate(payload);
    const ukKycRegistrationDetails = {
      registration: result.ukKycRegistration,
      registrationRequirement: UkKycRegistrationRequirementTypes.NOT_REQUIRED,
    } as UkKycRegistrationDetails;

    if (dispatch) {
      dispatch({
        type: "SET_UK_KYC_REGISTRATION_DATA",
        ukKycComplianceRegistrationData: ukKycRegistrationDetails,
        isUkKycComplianceRegistrationHardBlocked: false,
      });
    }
  }

  const isUkKycRegistrationEnabled = useFeatureFlag(
    SplitNames.CommsUkKycDpnRegistration,
  );
  function renderTwilioStateChild() {
    if (registration?.acknowledgedAt) {
      return null;
    }

    if (
      !registration &&
      state?.ukKycComplianceRegistrationData?.registrationRequirement ===
        UkKycRegistrationRequirementTypes.SOFT_BLOCK
    ) {
      return <UkKycInitialState />;
    }

    switch (registration?.status) {
      case UkKycRegistrationStatuses.DRAFT:
        return <UkKycInitialState />;
      case UkKycRegistrationStatuses.PENDING_REVIEW:
      case UkKycRegistrationStatuses.IN_REVIEW:
        return <UkKycPendingState />;
      case UkKycRegistrationStatuses.TWILIO_REJECTED:
        return <UkKycRejectedState />;
      case UkKycRegistrationStatuses.TWILIO_APPROVED:
        return <UkKycApprovedState onClick={handleAcknowledgedAt} />;
      default:
        return null;
    }
  }

  return (
    <IntlProvider>
      {isUkKycRegistrationEnabled && renderTwilioStateChild()}
    </IntlProvider>
  );
}
export const UkKycRegistration = withSplitClient(UkKycRegistrationContent);

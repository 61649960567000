import React from "react";
import { Banner, Button, Heading } from "@jobber/components";
import classnames from "classnames";
import { useIntl } from "react-intl";
import { wrapperMessages } from "jobber/chat/components/UkKycRegistration/wrapperMessages";
import styles from "jobber/chat/components/UkKycRegistration/UkKycRegistration.module.css";
import { rejectedStateMessages } from "./rejectedStateMessages";

export function UkKycRejectedState() {
  const { formatMessage } = useIntl();
  const cssClasses = classnames(
    styles.childWrapper,
    styles.extraPadding,
    "u-borderTop",
  );

  return (
    <>
      <div className={styles.bannerWrapper}>
        <Banner type={"warning"} dismissible={false}>
          {formatMessage(wrapperMessages.bannerMessage)}
        </Banner>
      </div>
      <div className={cssClasses}>
        <Heading level={4}>
          {formatMessage(rejectedStateMessages.moreInfoNeeded)}
        </Heading>
        <p>{formatMessage(rejectedStateMessages.verificationNotComplete)}</p>
        <Button
          fullWidth={true}
          size={"base"}
          variation={"work"}
          label={formatMessage(rejectedStateMessages.rejectedButtonText)}
          onClick={() => (window.location.href = "/text_messaging_settings")}
        />
      </div>
    </>
  );
}

import { defineMessages } from "react-intl";

const pageIdPrefix = "setupWizard.communityPage.";

export const messages = defineMessages({
  communityDefaultPageHeading1: {
    id: `${pageIdPrefix}communityDefaultPageHeading1`,
    defaultMessage: "Built by you.",
    description: "Community page heading one for the setup wizard",
  },
  communityDefaultPageHeading2: {
    id: `${pageIdPrefix}communityDefaultPageHeading2`,
    defaultMessage: "Powered by Jobber.",
    description: "Community page heading two for the setup wizard",
  },
  communityDefaultItem1Header: {
    id: `${pageIdPrefix}communityDefaultItem1Header`,
    defaultMessage: "THE community for home service professionals.",
    description: "Community page header one for the setup wizard",
  },
  communityDefaultItem1Subtext: {
    id: `${pageIdPrefix}communityDefaultItem1Subtext`,
    defaultMessage:
      "With members from all industries with all levels of experience, they get your challenges and want to celebrate your successes.",
    description: "Community page subtext one for the setup wizard",
  },
  communityDefaultItem2Header: {
    id: `${pageIdPrefix}communityDefaultItem2Header`,
    defaultMessage: "For home service pros built by home service pros.",
    description: "Community page header two for the setup wizard",
  },
  communityDefaultItem2Subtext: {
    id: `${pageIdPrefix}communityDefaultItem2Subtext`,
    defaultMessage:
      "Your experiences, your knowledge, your resources, and your support are what make this community so valuable and strong.",
    description: "Community page subtext two for the setup wizard",
  },
  communityDefaultItem3Header: {
    id: `${pageIdPrefix}communityDefaultItem3Header`,
    defaultMessage: "Find the answers and resources you need.",
    description: "Community page header three for the setup wizard",
  },
  communityDefaultItem3Subtext: {
    id: `${pageIdPrefix}communityDefaultItem3Subtext`,
    defaultMessage:
      "From info on all things home service, to accessing exclusive content and programs, the latest industry news, and Jobber tips and tricks.",
    description: "Community page subtext three for the setup wizard",
  },
  serviceProviderCollageAltText: {
    id: `${pageIdPrefix}serviceProviderCollageAltText`,
    defaultMessage: "service provider collage",
    description: "Service provider collage alt text for the setup wizard",
  },
});

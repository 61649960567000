import { defineMessages } from "react-intl";

const pageFormIdPrefix = "setupWizard.communityPageForm.";

export const messages = defineMessages({
  fullNamePlaceholder: {
    id: `${pageFormIdPrefix}fullNamePlaceholder`,
    defaultMessage: "First and last name",
    description: "Account owner full name for the setup wizard",
  },
  fullNameError: {
    id: `${pageFormIdPrefix}fullNameError`,
    defaultMessage: "Please fill in your first and last name",
    description: "Full name error for the setup wizard",
  },
  companyNamePlaceholder: {
    id: `${pageFormIdPrefix}companyNamePlaceholder`,
    defaultMessage: "Company name",
    description: "Company name for the setup wizard",
  },
  industryPlaceholder: {
    id: `${pageFormIdPrefix}industryPlaceholder`,
    defaultMessage: "Choose your industry",
    description: "Industry placeholder for the setup wizard",
  },
  industryError: {
    id: `${pageFormIdPrefix}industryError`,
    defaultMessage: "Please tell us the industry you work in",
    description: "Industry error for the setup wizard",
  },
  communityHeading: {
    id: `${pageFormIdPrefix}communityHeading`,
    defaultMessage: "A few more details",
    description: "More details to get the account set up",
  },
  communitySubmissionButton: {
    id: `${pageFormIdPrefix}communitySubmissionCreateButton`,
    defaultMessage: "Create",
    description: "The button to submit the community form",
  },
});

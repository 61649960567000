import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addToPlan: {
    id: "billing.subscriptionAddonCard.addToPlan",
    defaultMessage: "Add",
    description: "Add to plan button text",
  },
  removeFromPlan: {
    id: "billing.subscriptionAddonCard.removeFromPlan",
    defaultMessage: "Remove",
    description: "Remove from plan button text",
  },
});

import type { ApolloError, FetchResult } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import type {
  AiReceptionistSmsStateEditMutation,
  AiReceptionistSmsStateQuery,
  AiReceptionistSmsStateType,
} from "~/utilities/API/graphql";
import {
  AI_RECEPTIONIST_SMS_STATE_MUTATION,
  AI_RECEPTIONIST_SMS_STATE_QUERY,
} from "./AiReceptionist.graphql";

export interface UseAiReceptionist {
  aiReceptionistSmsState?: AiReceptionistSmsStateType;
  aiReceptionistError?: ApolloError;
  aiReceptionistLoading: boolean;
  toggleAiReceptionist(
    value: boolean,
  ): Promise<FetchResult<AiReceptionistSmsStateEditMutation>>;
}

export function useAiReceptionist(conversationId: string): UseAiReceptionist {
  const { data, error, loading } = useQuery<AiReceptionistSmsStateQuery>(
    AI_RECEPTIONIST_SMS_STATE_QUERY,
    {
      variables: {
        id: conversationId,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    },
  );

  const [aiReceptionistToggle] =
    useMutation<AiReceptionistSmsStateEditMutation>(
      AI_RECEPTIONIST_SMS_STATE_MUTATION,
    );
  const toggleAiReceptionist = async (value: boolean) => {
    return aiReceptionistToggle({
      variables: {
        id: conversationId,
        status: value,
      },
    });
  };

  return {
    aiReceptionistSmsState: data?.aiReceptionistSmsState,
    aiReceptionistError: error,
    aiReceptionistLoading: loading,
    toggleAiReceptionist,
  };
}

import { Industry } from "~/utilities/API/graphql";
import type { SetupWizardContextType } from "jobber/setupWizard/context/createSetupWizardContext";
import {
  companyRevenueArray,
  companySizeArray,
  topPriorityArray,
} from "./components/constants";
import type {
  IndustryCategory,
  QueryResultData,
  SetupWizardData,
  SetupWizardExperiments,
  SetupWizardStepProps,
} from "./types";
import type { SetupWizardLoaderProps } from "./SetupWizard.loader";
import type { SetupWizardRouteProps } from "./SetupWizardRoute";

export const mockIndustryMap: IndustryCategory[] = [
  {
    category: "Hi Tech",
    industries: [
      { displayName: "Computers & IT", industryEnum: Industry.COMPUTERS_IT },
    ],
  },
  {
    category: "Other",
    industries: [
      {
        displayName: "Appliance Repair",
        industryEnum: Industry.APPLIANCE_REPAIR,
      },
      {
        displayName: "Flooring Service",
        industryEnum: Industry.FLOORING_SERVICE,
      },
    ],
  },
];

export const mockPromotionData = {
  promoTrackingTag: "test_promo_tracking_tag",
  showPromo: true,
  showPromoStatusQuestionKey: "Promo status",
  showPromoStatusInterestedValue: "Interested",
  promoDetails: {
    welcomeText: "Welcome text",
    title: "Title",
    information: "Info",
    subtext: "Subtext",
    buttonText: "Button text",
  },
};

export const mockEmptyPromotionData = {
  promoTrackingTag: "",
  showPromo: false,
  showPromoStatusQuestionKey: "Promo status",
  showPromoStatusInterestedValue: "Interested",
  promoDetails: {
    welcomeText: "",
    title: "",
    information: "",
    subtext: "",
    buttonText: "",
  },
};

export const mockExperiments: SetupWizardExperiments = {
  happyPathP1V2: false,
};

export const mockSetupWizardStepProps: SetupWizardStepProps = {
  navigation: {
    isFinalStep: false,
    nextCtaCopy: "Dummy CTA copy",
    onNext: jest.fn(),
  },
  industryMap: mockIndustryMap,
  onMobileWeb: false,
  promoDetails: mockEmptyPromotionData.promoDetails,
  promoTrackingTag: mockEmptyPromotionData.promoTrackingTag,
  showPromoStatusQuestionKey: "Promo status",
  showPromoStatusInterestedValue: "Interested",
  signupConsentData: {
    hasData: true,
    consentRegion: "canada",
  },
  attrName: "",
};

export const mockSetupWizardData: SetupWizardData = {
  accountOwnerName: "Test Account Owner",
  companyName: "Test Company Name",
  companySize: companySizeArray[0].value,
  heardAboutUs: "Test heard about us value",
  industry: Industry.COMPUTERS_IT,
  phoneNumber: "(555) 555-5555",
  questionsAndAnswers: [
    {
      question: "business_revenue",
      answer: companyRevenueArray[0].value,
    },
    {
      question: "business_goals",
      answer: topPriorityArray[0].value,
    },
  ],
  smsOptIn: false,
};

export const companyNameCommunity = "Test Account Owner's Company";

export const mockQueryResult: QueryResultData = {
  account: {
    name: "Carpet Cleaning Inc.",
    phone: "(555) 555-3333",
    industry: Industry.CARPET_CLEANING,
    setupWizard: {
      companySize: companySizeArray[1].value,
      heardAboutUs: "Heard about you from a friend",
      questionsAndAnswers: [
        {
          question: "business_revenue",
          answer: companyRevenueArray[1].value,
        },
        {
          question: "business_goals",
          answer: topPriorityArray[1].value,
        },
      ],
      smsOptIn: false,
    },
    accountOwner: {
      fullName: "John Doe",
      email: { raw: "john.doe@testemail.com" },
    },
  },
};

export const mockSetupWizardContext: SetupWizardContextType = {
  error: "",
  saving: false,
  wizardData: {},
  updateWizardData: jest.fn(),
  topPriorityOptions: [],
  experiments: {} as SetupWizardExperiments,
  updateExperiments: jest.fn(),
  accountCreatedAt: "",
};

export const mockSetupWizardRouteProps: SetupWizardRouteProps = {
  industryMap: mockIndustryMap,
  promotionData: mockEmptyPromotionData,
  signupConsentData: {
    hasData: true,
    consentRegion: "canada",
  },
  attrName: "",
};

export const mockSetupWizardLoaderProps: SetupWizardLoaderProps = {
  industryMap: mockIndustryMap,
  promotionData: mockEmptyPromotionData,
  experiments: mockExperiments,
  accountCreatedAt: "",
  signupConsentData: {
    hasData: true,
    consentRegion: "canada",
  },
  attrName: "",
};

export const mockDatadogBoundaryProvider = {
  useDatadogLogger: jest.fn(() => ({
    logError: jest.fn(),
    logInfo: jest.fn(),
  })),
};

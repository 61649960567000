import React from "react";
import { Button } from "@jobber/components/Button";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components";
import { messages } from "./messages";

interface RedirectToPricingPageButtonProps {
  addonSetName?: string;
}

export function RedirectToPricingPageButton(
  props: RedirectToPricingPageButtonProps,
) {
  const { formatMessage } = useIntl();

  return (
    <Content>
      <Button
        label={formatMessage(messages.inTrialButton)}
        ariaLabel={formatMessage(messages.inTrialButton)}
        size={"base"}
        type={"primary"}
        onClick={navigateToPricing}
      />
      {props.addonSetName && (
        <Text variation={"info"}>
          <Emphasis variation={"italic"}>
            {formatMessage(messages.inTrialFooter, {
              addonSetName: props.addonSetName,
            })}
          </Emphasis>
        </Text>
      )}
    </Content>
  );
}

function navigateToPricing() {
  window.location.assign("/accounts/billing_info/pricing");
}

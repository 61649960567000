import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Modal } from "@jobber/components/Modal";
import { Emphasis } from "@jobber/components/Emphasis";
import { Button } from "@jobber/components/Button";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { InputText } from "@jobber/components/InputText";
import { Banner } from "@jobber/components/Banner";
import {
  type AiAssistantFeedbackCreateMutation,
  type AiAssistantFeedbackCreateMutationVariables,
  AiAssistantFeedbackSentiment,
} from "~/utilities/API/graphql";
import { AI_ASSISTANT_CREATE_FEEDBACK } from "./JobberAssistant.graphql";
import styles from "./Feedback.module.css";

interface FeedbackProps {
  readonly requestId: string;
}
type OptionalFeedbackSentiment = AiAssistantFeedbackSentiment | undefined;

export function Feedback({ requestId }: FeedbackProps) {
  const [gotFeedback, setGotFeedback] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState<OptionalFeedbackSentiment>(undefined);

  const [createFeedback] = useMutation<
    AiAssistantFeedbackCreateMutation,
    AiAssistantFeedbackCreateMutationVariables
  >(AI_ASSISTANT_CREATE_FEEDBACK);

  return (
    <>
      <div className={styles.feedback}>
        {!gotFeedback && (
          <>
            <Button
              icon={"thumbsUp"}
              onClick={() => modalLaunch(AiAssistantFeedbackSentiment.HELPFUL)}
              type="tertiary"
              size="base"
              variation="subtle"
              label=""
              ariaLabel="Yes"
            />
            <Button
              icon={"thumbsDown"}
              onClick={() =>
                modalLaunch(AiAssistantFeedbackSentiment.NOT_HELPFUL)
              }
              type="tertiary"
              size="base"
              variation="subtle"
              label=""
              ariaLabel="No"
            />
          </>
        )}
        {gotFeedback && (
          <Text align="end" variation="subdued" size="small">
            <Icon name="checkmark" size="small" />
            &nbsp;
            <Emphasis variation="italic">Thanks for your feedback</Emphasis>
          </Text>
        )}
      </div>
      {modalOpen && (
        <FeedbackModal
          modalOpen={modalOpen}
          type={type}
          errorMessage={errorMessage}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      )}
    </>
  );

  function modalLaunch(feedbackType: AiAssistantFeedbackSentiment) {
    setType(feedbackType);
    setErrorMessage("");
    setModalOpen(true);
  }
  function handleClose() {
    setModalOpen(false);
  }
  async function handleSubmit({ comment, sentiment }: FeedbackFormValues) {
    await createFeedback({
      variables: {
        requestId,
        input: {
          sentiment,
          ...(comment !== undefined ? { comment } : { comment: "" }),
        },
      },
    })
      .then(result => {
        const errors = result?.data?.aiAssistantFeedbackCreate?.userErrors;

        if (errors && errors?.length > 0) {
          setErrorMessage(errors[0].message);
        } else {
          setGotFeedback(true);
          setModalOpen(false);
        }
      })
      .catch(error => {
        setErrorMessage(error);
      });
  }
}

interface FeedbackFormValues {
  comment?: string;
  sentiment?: AiAssistantFeedbackSentiment;
}

interface FeedbackModalProps {
  readonly modalOpen: boolean;
  readonly type: OptionalFeedbackSentiment;
  readonly errorMessage?: string;
  readonly onSubmit: (formValues: FeedbackFormValues) => void;
  readonly onClose: () => void;
}
function FeedbackModal({
  modalOpen,
  type,
  errorMessage,
  onSubmit,
  onClose,
}: FeedbackModalProps) {
  const [comment, setComment] = useState("");
  const [sentiment, setSentiment] = useState(type);

  return (
    <Modal
      title={"Send feedback"}
      open={modalOpen}
      primaryAction={{
        label: "Send Feedback",
        onClick: () => onSubmit({ comment, sentiment }),
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: () => onClose(),
      }}
      onRequestClose={() => onClose()}
    >
      <Content>
        {errorMessage && (
          <Banner type={"error"} dismissible={false}>
            There was an error saving your feedback. Please try again.
          </Banner>
        )}
        <div className={styles.modalRow}>
          <Text>Was this response from Jobber Copilot helpful?</Text>
          <Button
            icon={"thumbsUp"}
            onClick={() => setSentiment(AiAssistantFeedbackSentiment.HELPFUL)}
            type="tertiary"
            label=""
            ariaLabel="Yes"
            variation={
              sentiment == AiAssistantFeedbackSentiment.HELPFUL
                ? "work"
                : "subtle"
            }
          />
          <Button
            icon={"thumbsDown"}
            onClick={() =>
              setSentiment(AiAssistantFeedbackSentiment.NOT_HELPFUL)
            }
            type="tertiary"
            variation={
              sentiment == AiAssistantFeedbackSentiment.NOT_HELPFUL
                ? "destructive"
                : "subtle"
            }
            label=""
            ariaLabel="No"
          />
        </div>
        <InputText
          description="Briefly provide us some feedback around the response"
          multiline
          rows={3}
          value={comment}
          // TODO - seems to be a bug currently with maxlength and multiline input
          // maxLength={350}
          onChange={(value: string) => setComment(value)}
        />
      </Content>
    </Modal>
  );
}

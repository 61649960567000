import React from "react";
import { Glimmer, Icon } from "@jobber/components";
import { motion } from "framer-motion";
import styles from "./Loading.module.css";

export function Loading() {
  return (
    <div className={styles.loadingContainer} data-testid="loading-container">
      <div className={styles.icon}>
        <Icon name="sparkles" color="success" />
      </div>
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className={styles.glimmer}
      >
        <Glimmer.Text />
      </motion.div>
    </div>
  );
}

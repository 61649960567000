import React, { type ReactElement, useMemo, useState } from "react";
import { Text } from "@jobber/components/Text";
import { useQuery } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import { Gallery } from "@jobber/components/Gallery";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Body, Cell, Row } from "components/Table";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { PRODUCT_OR_SERVICES_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import type {
  JobCreateLineItemsInput,
  JobEditLineItemsInput,
  ProductsQuery,
} from "~/utilities/API/graphql";
import { formatCurrency } from "utilities/formatCurrency";
import type { LineItem as LineItemType } from "~/jobber/lineItems/types";
import { messages } from "jobber/workOrders/components/JobCost/components/LineItemsTable/messages";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks/lineItemsBulkEditReducer";
import { LineItem } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/LineItemInput/LineItem";
import styles from "./LineItemsTableBody.module.css";
import { OverrideDates } from "./OverrideDates";
import {
  columnsRequiringJobCostingFeature,
  columnsRequiringPricingPermission,
} from "../constants";
import {
  messages as queryMessages,
  useJobCreateLineItemsMutation,
  useJobDeleteLineItemsMutation,
  useJobEditLineItemMutation,
} from "../../../hooks";

interface LineItemsTableBodyProps {
  lineItems: LineItemType[];
  dispatch: (action: LineItemsAction) => void;
  jobId: string;
  children?: JSX.Element;
}

export function LineItemsTableBody({
  lineItems,
  dispatch,
  jobId,
  children,
}: LineItemsTableBodyProps) {
  const { formatMessage } = useIntl();
  const { features, currencySymbol } = useAccount();
  const canViewCostColumn =
    features.jobCosting.enabled && features?.quoteMargins.enabled;
  const { can } = useAuthorization();
  const canViewPricing = can("view", "Pricing");

  return (
    <Body>
      <>
        {lineItems.map(lineItem =>
          renderLineItem({
            lineItem,
            canViewPricing,
            canViewCostColumn,
            dispatch,
            jobId,
            currencySymbol,
            formatMessage,
          }),
        )}
        {children}
      </>
    </Body>
  );
}

function renderLineItem({
  lineItem,
  canViewPricing,
  canViewCostColumn,
  dispatch,
  jobId,
  currencySymbol,
  formatMessage,
}: {
  lineItem: LineItemType;
  canViewPricing: boolean;
  canViewCostColumn: boolean;
  dispatch: (action: LineItemsAction) => void;
  jobId: string;
  currencySymbol: string;
  formatMessage: (
    messageDescriptor: MessageDescriptor,
    values?: unknown,
  ) => string;
}) {
  const cellsToRender = [
    <Cell variation="bold" key="description" size="large">
      <div className={styles.lineItemNameImageContainer}>
        <div className={styles.lineItemNameAndDescriptionContainer}>
          <h5 className={styles.heading}>{lineItem.name}</h5>
          {lineItem.overrideDates && lineItem.overrideDates.length > 0 && (
            <div className={styles.variationDates}>
              <OverrideDates overrideDates={lineItem.overrideDates} />
            </div>
          )}
          {lineItem.description && (
            <div className={styles.description}>
              <Text>{renderLineItemDescription(lineItem.description)}</Text>
            </div>
          )}

          {!lineItem.taxable && (
            <div className={styles.taxableContainer} data-testid="taxableLabel">
              <Text size="small">
                {formatMessage(messages.lineItemNonTaxableLabel)}
              </Text>
            </div>
          )}
        </div>
        {lineItem.image && (
          <div onClick={e => e.stopPropagation()} aria-hidden>
            <Gallery
              files={[
                {
                  name: lineItem.image?.fileName || "",
                  type: lineItem.image?.contentType || "",
                  size: lineItem.image?.fileSize || 0,
                  src: lineItem.image?.url || "",
                  progress: 1,
                  key: `${lineItem.image?.fileName}_${lineItem.image?.fileSize}`,
                },
              ]}
            />
          </div>
        )}
      </div>
    </Cell>,
    <Cell key="quantity" className={styles.quantityCell}>
      <div className={styles.alignCenter}>
        <span className={styles.mobileTitle}>
          {formatMessage(messages.lineItemQuantity)}
        </span>
        <span data-testid="quantity">{lineItem.quantity}</span>
      </div>
    </Cell>,
    <Cell key="cost">
      <div className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          {formatMessage(messages.lineItemCost)}
        </span>
        <span className={styles.numeric} data-testid="cost">
          {formatCurrency(lineItem.unitCost || 0, currencySymbol)}
        </span>
      </div>
    </Cell>,
    <Cell key="price">
      <div className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          {formatMessage(messages.lineItemPrice)}
        </span>
        <span className={styles.numeric} data-testid="price">
          {formatCurrency(lineItem.unitPrice || 0, currencySymbol)}
        </span>
      </div>
    </Cell>,
    <Cell key="total">
      <div className={styles.alignRight}>
        <span className={styles.mobileTitle}>
          {formatMessage(messages.lineItemTotal)}
        </span>
        <span className={styles.numeric} data-testid="total">
          {formatCurrency(lineItem.totalPrice || 0, currencySymbol)}
        </span>
      </div>
    </Cell>,
  ];

  const permissionFilteredCellsToRender = cellsToRender
    .filter(
      cell =>
        !columnsRequiringPricingPermission.includes(
          `${cell.key?.toString()}`,
        ) || canViewPricing,
    )
    .filter(
      cell =>
        !columnsRequiringJobCostingFeature.includes(
          `${cell.key?.toString()}`,
        ) || canViewCostColumn,
    );

  return (
    <LineItemRow
      key={lineItem.reactKey}
      lineItem={lineItem}
      cellsToRender={permissionFilteredCellsToRender}
      dispatch={dispatch}
      jobId={jobId}
      canViewCostColumn={canViewCostColumn}
    />
  );
}

interface LineItemRowProps {
  lineItem: LineItemType;
  cellsToRender: ReactElement[];
  dispatch: (action: LineItemsAction) => void;
  jobId: string;
  canViewCostColumn: boolean;
}

function LineItemRow({
  lineItem,
  cellsToRender,
  dispatch,
  jobId,
  canViewCostColumn,
}: LineItemRowProps) {
  const { can } = useAuthorization();
  const canViewJobCosts = can("view", "JobCosts"),
    canEditJobs = can("edit", "Jobs"),
    canViewPricing = can("view", "Pricing");
  const [editMode, setEditMode] = useState(!lineItem.id);

  const { data } = useQuery<ProductsQuery>(PRODUCT_OR_SERVICES_QUERY);

  const { handleDeleteLineItem } = useJobDeleteLineItemsMutation({
    jobId,
    lineItemId: lineItem.id,
    canViewJobCosts,
    canViewPricing,
    onSuccess: () => evictLineItem(dispatch, lineItem),
  });

  const { handleOnDeleteLineItem } = deleteLineItem(
    dispatch,
    handleDeleteLineItem,
  );

  const { handleJobEditLineItem } = useJobEditLineItemMutation(
    jobId,
    canViewJobCosts,
    canViewPricing,
    () => {
      updateLineItem(dispatch, lineItem);
      setEditMode(false);
    },
  );

  const { handleJobCreateLineItems } = useJobCreateLineItemsMutation({
    jobId,
    canViewJobCosts,
    canViewPricing,
    onSuccess: () => {
      saveNewLineItem(dispatch, lineItem);
      setEditMode(false);
    },
  });

  const { handleOnSaveLineItem } = saveLineItem(
    dispatch,
    handleJobCreateLineItems,
    handleJobEditLineItem,
  );

  const handleRowClick = useMemo(() => {
    if (!canEditJobs || !canViewPricing) {
      return undefined;
    }

    return () => {
      if (!editMode) {
        setEditMode(true);
      }
    };
  }, [canEditJobs, canViewPricing, editMode, setEditMode]);

  return (
    <Row
      key={lineItem.reactKey}
      testId={"lineItemRow"}
      onClick={handleRowClick}
    >
      {editMode ? (
        // Defining the style directly on this element is required because Table.module.css has styles
        // with many selectors that are trying to add unneeded padding to this element.
        <td colSpan={100} data-testid="lineItemEditing" style={{ padding: 0 }}>
          <LineItem.Container>
            <LineItem.InputContainer>
              <LineItem.InputRow>
                <LineItem.Name
                  lineItem={lineItem}
                  onChange={dispatch}
                  initialOptions={data?.products.nodes || []}
                  shouldAutoFocus={true}
                />
                <LineItem.Quantity
                  quantity={lineItem.quantity}
                  onChange={value => {
                    dispatch({
                      type: LineItemsBulkEditActionTypes.UpdateLineItem,
                      reactKey: lineItem.reactKey,
                      field: "quantity",
                      value,
                    });
                  }}
                  reactKey={lineItem.reactKey}
                />
                {canViewCostColumn && (
                  <LineItem.UnitCost
                    unitCost={lineItem.unitCost}
                    onChange={value => {
                      dispatch({
                        type: LineItemsBulkEditActionTypes.UpdateLineItem,
                        reactKey: lineItem.reactKey,
                        field: "unitCost",
                        value,
                      });
                    }}
                    reactKey={lineItem.reactKey}
                  />
                )}
                <LineItem.UnitPrice
                  unitPrice={lineItem.unitPrice}
                  onChange={value => {
                    dispatch({
                      type: LineItemsBulkEditActionTypes.UpdateLineItem,
                      reactKey: lineItem.reactKey,
                      field: "unitPrice",
                      value,
                    });
                  }}
                  reactKey={lineItem.reactKey}
                />
                <LineItem.TotalPrice
                  totalPrice={lineItem.totalPrice}
                  onChange={value => {
                    dispatch({
                      type: LineItemsBulkEditActionTypes.UpdateLineItem,
                      reactKey: lineItem.reactKey,
                      field: "totalPrice",
                      value,
                    });
                  }}
                  reactKey={lineItem.reactKey}
                />
              </LineItem.InputRow>
              <LineItem.InputRow>
                <LineItem.Description
                  description={lineItem.description}
                  reactKey={lineItem.reactKey}
                  onChange={value => {
                    dispatch({
                      type: LineItemsBulkEditActionTypes.UpdateLineItem,
                      reactKey: lineItem.reactKey,
                      field: "description",
                      value,
                    });
                  }}
                />
              </LineItem.InputRow>
            </LineItem.InputContainer>
            <LineItem.ActionContainer>
              <LineItem.DeleteButton
                onDelete={handleOnDeleteLineItem}
                lineItem={lineItem}
              />
              <LineItem.SaveButton
                onSaveLineItem={handleOnSaveLineItem}
                lineItem={lineItem}
              />
            </LineItem.ActionContainer>
          </LineItem.Container>
        </td>
      ) : (
        cellsToRender
      )}
    </Row>
  );
}

function evictLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  lineItem: LineItemType,
) {
  dispatch({
    type: LineItemsBulkEditActionTypes.DeleteLineItem,
    reactKey: lineItem.reactKey,
  });
}

function updateLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  lineItem: LineItemType,
) {
  dispatch({
    type: LineItemsBulkEditActionTypes.FullUpdate,
    reactKey: lineItem.reactKey,
    value: { ...lineItem },
  });
}

function saveNewLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  lineItem: LineItemType,
) {
  dispatch({
    type: LineItemsBulkEditActionTypes.FullUpdate,
    reactKey: lineItem.reactKey,
    value: { ...lineItem },
  });
}

function deleteLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  handleDeleteLineItem: () => Promise<void>,
): { handleOnDeleteLineItem: (lineItem: LineItemType) => Promise<void> } {
  async function handleOnDeleteLineItem(lineItem: LineItemType) {
    if (!lineItem.id) {
      evictLineItem(dispatch, lineItem);
      showToast({
        message: queryMessages.lineItemDeleted,
        variation: "success",
      });
    } else {
      await handleDeleteLineItem();
    }
  }

  return { handleOnDeleteLineItem };
}

function saveLineItem(
  dispatch: React.Dispatch<LineItemsAction>,
  handleJobCreateLineItems: (
    input: JobCreateLineItemsInput,
  ) => Promise<string[] | undefined> | Promise<void>,
  handleJobEditLineItem: (input: JobEditLineItemsInput) => Promise<void>,
): {
  handleOnSaveLineItem: (newLineItemState: LineItemType) => Promise<void>;
} {
  async function handleOnSaveLineItem(newLineItemState: LineItemType) {
    const lineItemDetails = {
      description: newLineItemState.description,
      name: newLineItemState.name,
      quantity: newLineItemState.quantity,
      unitCost: newLineItemState.unitCost,
      unitPrice: newLineItemState.unitPrice,
    };

    if (!newLineItemState.id) {
      const globalLineItemIds = await handleJobCreateLineItems({
        lineItems: [
          {
            ...lineItemDetails,
            saveToProductsAndServices: false,
            taxable: newLineItemState.taxable,
            unitCost: newLineItemState.unitCost || 0,
            unitPrice: newLineItemState.unitPrice || 0,
          },
        ],
      });

      if (
        Array.isArray(globalLineItemIds) &&
        typeof globalLineItemIds[0] === "string"
      ) {
        dispatch({
          type: LineItemsBulkEditActionTypes.Destroy,
          reactKey: newLineItemState.reactKey,
        });
      }
    } else {
      await handleJobEditLineItem({
        lineItems: [
          {
            ...lineItemDetails,
            lineItemId: newLineItemState.id,
          },
        ],
      });
    }
  }

  return { handleOnSaveLineItem };
}

function renderLineItemDescription(description: string) {
  if (description.includes("\n")) {
    return description.split("\n").map((paragraph: string, index: number) => {
      if (index === 0) {
        return paragraph;
      }
      return (
        <>
          <br></br>
          {paragraph}
        </>
      );
    });
  }

  return description;
}

// The ConversationLoader component currently duplicates ChatWrapper and the Conversation component
import type { FetchResult } from "@apollo/client";
import React from "react";
// calls the existing Chat component. This was done to create a placeholder for React Native Gifted Chat (will be done in future).
// ChatWrapper component can also be deleted and replaced by Conversation component to have it more inline with the
// recommended component and directory structure.
import { Chat } from "jobber/chat/components/ChatDrawer/Chat/Chat";
import type {
  AiReceptionistSmsStateEditMutation,
  AiReceptionistSmsStateType,
  ConversationMessagesQuery,
  MessageStatusEnum,
  Recipient,
  Scalars,
} from "~/utilities/API/graphql";

export interface ConversationProps {
  messages: NonNullable<
    NonNullable<ConversationMessagesQuery["conversation"]>["messages"]["edges"]
  >[0]["node"][];
  hasNextPage: boolean;
  recipient: Recipient | undefined;
  getMoreMessages(): void;
  markConversationAsRead(): void;
  afterSend?(recipient: Recipient | undefined): void;
  afterInput?(
    message: string,
    clientGeneratedId: Scalars["Uuid"]["input"],
    status: MessageStatusEnum,
  ): void;
  aiReceptionistSmsState: AiReceptionistSmsStateType | undefined;
  toggleAiReceptionist: (
    value: boolean,
  ) => Promise<FetchResult<AiReceptionistSmsStateEditMutation>>;
}

export function Conversation({
  messages,
  hasNextPage,
  recipient,
  getMoreMessages,
  markConversationAsRead,
  afterSend,
  afterInput,
  aiReceptionistSmsState,
  toggleAiReceptionist,
}: ConversationProps) {
  function internalAfterSend() {
    if (afterSend) {
      afterSend(recipient);
    }
  }

  return (
    <Chat
      messages={messages}
      hasNextPage={hasNextPage}
      getMoreMessages={getMoreMessages}
      markConversationAsRead={markConversationAsRead}
      afterSend={internalAfterSend}
      afterInput={afterInput}
      aiReceptionistSmsState={aiReceptionistSmsState}
      toggleAiReceptionist={toggleAiReceptionist}
    />
  );
}

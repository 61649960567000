import { defineMessages } from "react-intl";

export const wrapperMessages = defineMessages({
  bannerMessage: {
    id: "ukKycRegistration.wrapper.bannerMessage",
    defaultMessage:
      "You will lose access to messages if verification is not complete by September 30",
    description: "Banner warning message for UK KYC registration",
  },
  pendingStateBannerMessage: {
    id: "ukKycRegistration.wrapper.pendingStateBannerMessage",
    defaultMessage:
      "Your application was submitted! We will notify you when the approval process is complete, which typically takes up to three days.",
    description: "Pending state banner warning message for UK KYC registration",
  },
});

import React from "react";
import {
  type BarDatum,
  type BarTooltipProps,
  type ComputedDatum,
  ResponsiveBar,
} from "@nivo/bar";
import {
  AtlantisThemeContextProvider,
  useAtlantisTheme,
} from "@jobber/components";
import { formatTick } from "jobber/dataVisualizations/utils/dataVisualizationsUtils";

export interface BarChartProps {
  keys: string[];
  xTickValues?: string[];
  yTickValues: number[];
  data: BarDatum[];
  tooltip?: React.FC<BarTooltipProps<BarDatum>>;
  groupMode?: "stacked" | "grouped";
  innerPadding?: number;
  colors?: string[];
  shouldShowTickValues?: boolean;
  yTickPrefix?: string;
  clickHandler?: (data: ComputedDatum<BarDatum>) => void;
  axisBottomFormatter?: (value: string) => string;
}

export function BarChart(props: BarChartProps) {
  return (
    <AtlantisThemeContextProvider>
      <InternalBarChart {...props} />
    </AtlantisThemeContextProvider>
  );
}

function InternalBarChart({
  keys, // keys are used to determine the order of the bars
  xTickValues,
  yTickValues,
  data,
  tooltip,
  groupMode = "stacked",
  innerPadding = 0,
  colors,
  shouldShowTickValues,
  yTickPrefix,
  clickHandler,
  axisBottomFormatter,
}: BarChartProps) {
  const { tokens } = useAtlantisTheme();
  const defaultColors = [tokens["color-green"], tokens["color-grey--light"]];

  return (
    <ResponsiveBar
      onClick={datum => {
        if (clickHandler) {
          clickHandler(datum);
        }
      }}
      data={data}
      keys={keys}
      indexBy="xAxisTick"
      margin={{
        top: tokens["space-larger"],
        right: tokens["space-base"],
        bottom: shouldShowTickValues
          ? tokens["space-large"]
          : tokens["space-largest"],
        left: tokens["space-larger"] + tokens["space-smaller"],
      }}
      innerPadding={innerPadding}
      padding={keys.length > 1 ? 0.4 : 0.65}
      valueScale={{
        type: "linear",
      }}
      indexScale={{ type: "band", round: true }}
      colors={colors || defaultColors}
      colorBy="id"
      groupMode={groupMode}
      enableLabel={false}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: tokens["color-text--secondary"],
              fontSize: tokens["typography--fontSize-small"],
            },
          },
        },
      }}
      borderRadius={2}
      enableGridY={true}
      gridYValues={shouldShowTickValues ? yTickValues : [0]}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: tokens["space-small"],
        tickRotation: 0,
        tickValues: xTickValues,
        format: value =>
          axisBottomFormatter ? axisBottomFormatter(value) : value,
      }}
      axisLeft={{
        format: value => formatTick(value, { yTickPrefix }),
        tickValues: yTickValues,
        tickSize: 0,
        tickPadding: tokens["space-small"],
        tickRotation: 0,
      }}
      role="application"
      tooltip={tooltip}
    />
  );
}

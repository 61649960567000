import React, { useContext } from "react";
import { Glimmer } from "@jobber/components/Glimmer";
import type { IconNames } from "@jobber/design";
import { AddonUpsellContext } from "~/jobber/billing/context/AddonUpsellContext/AddonUpsellContext";
import {
  trackCtaClicked,
  trackCtaDismissed,
} from "jobber/billing/utils/trackInteractions";
import { AddonPurchaseModal } from "legacy/jobber/billing/features/AddonPurchaseModal";
import { ChatAboutAddonsButton } from "./components/ChatAboutAddonsButton/ChatAboutAddonsButton";
import { RedirectToPricingPageButton } from "./components/RedirectToPricingButton/RedirectToPricingPageButton";

interface PurchaseButtonProps {
  icon?: IconNames;
  buttonText?: string;
}

interface LandingPageCtaButtonProps {
  loading: boolean;
  addonSetIdentifier?: string;
  addonSetName?: string;
  recurlyPublicKey: string;
  inTrial: boolean;
  isMobileBilled: boolean;
  salesforceTrackingId: string | null;
  onPurchaseSuccess?: () => void;
  purchaseButtonProps?: PurchaseButtonProps;
}

export function LandingPageCtaButton(props: LandingPageCtaButtonProps) {
  const addonUpSell = useContext(AddonUpsellContext);

  if (props.inTrial) {
    return <RedirectToPricingPageButton addonSetName={props.addonSetName} />;
  }

  if (props.loading || !props.addonSetIdentifier) {
    return (
      <Glimmer shape="rectangle" size="largest" timing="base" width={115} />
    );
  }

  if (props.isMobileBilled) {
    return (
      <ChatAboutAddonsButton
        addonSetIdentifier={props.addonSetIdentifier}
        loading={props.loading}
      />
    );
  }

  return (
    <AddonPurchaseModal
      recurlyPublicKey={props.recurlyPublicKey}
      addonSetIdentifier={props.addonSetIdentifier}
      addonUpsell={addonUpSell}
      buttonProps={{
        buttonSize: "base",
        ...props.purchaseButtonProps,
      }}
      onOpen={() => {
        trackCtaClicked({
          name: `clicked_add_to_plan_for_${props.addonSetIdentifier}`,
          salesforceId: props.salesforceTrackingId,
        });
      }}
      onDismiss={() => {
        trackCtaDismissed({
          name: `${props.addonSetIdentifier}_purchase_page_purchase_modal`,
          salesforceId: props.salesforceTrackingId,
        });
      }}
      successAction={() => {
        trackCtaClicked({
          name: `${props.addonSetIdentifier}_purchase_page_purchase_modal`,
          salesforceId: props.salesforceTrackingId,
        });

        if (props.onPurchaseSuccess) {
          props.onPurchaseSuccess();
        }
      }}
    />
  );
}

import { Button } from "@jobber/components/Button";
import { InputText } from "@jobber/components/InputText";
import type { IconColorNames, IconNames } from "@jobber/design";
import React, { useEffect, useState } from "react";
import { SideDrawer } from "@jobber/components";
import type {
  AiAssistantAction,
  AiAssistantFollowup,
  AiAssistantImage,
  AiAssistantQuestionInterpretation,
} from "~/utilities/API/graphql";
import { ConversationList } from "./ConversationList";
import { messageType } from "./ConversationListItem";
import type { ConversationListItem } from "./ConversationListItem";
import styles from "./JobberAssistant.module.css";
import type { PromptType } from "../AssistantPromptEventQueueProvider";

export interface InteractionItem {
  id: string;
  requestId: string;
  createdAt: string;
  message: string;
  footnotes?: string[];
  visuals: AiAssistantImage[];
  questionInterpretation: AiAssistantQuestionInterpretation | undefined;
  type: messageType;
  actions?: AiAssistantAction[];
  followups: AiAssistantFollowup[];
}

interface JobberAssistantProps {
  conversationList: InteractionItem[];
  onPrompt: (prompt: PromptType) => void;
  showInitialMessage: boolean;
}

// eslint-disable-next-line max-statements
export const JobberAssistant = ({
  conversationList,
  onPrompt,
  showInitialMessage,
}: JobberAssistantProps) => {
  const [promptInput, setPromptInput] = useState("");
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(false);

  const listItems = buildListItems(conversationList);

  // Clear the loading state if latest list item is a response.
  useEffect(() => {
    if (conversationList.length === 0) {
      return;
    }

    setIsLoadingMessage(
      conversationList[conversationList.length - 1].type ===
        messageType.REQUEST,
    );
    setIsSendDisabled(
      conversationList[conversationList.length - 1].type ===
        messageType.REQUEST ||
        conversationList[conversationList.length - 1].type ===
          messageType.PARTIAL_RESPONSE,
    );
  }, [conversationList]);

  return (
    <div className={styles.chatWindow} data-testid="jobber-assistant-chat">
      <div className={styles.listContainer}>
        <div className={styles.listContent}>
          <ConversationList
            items={listItems}
            isLoadingMessage={isLoadingMessage}
          />
        </div>
      </div>
      <SideDrawer.Footer>
        <div className={styles.promptContainer}>
          <InputText
            placeholder="Ask a question..."
            value={promptInput}
            onChange={handleInput}
            onEnter={handleSend}
            autofocus
          />
          <Button
            label=""
            ariaLabel="Send"
            icon={"backArrow"}
            onClick={handleSend}
            disabled={isSendDisabled}
          />
        </div>
      </SideDrawer.Footer>
    </div>
  );

  function handleInput(newInput: string) {
    setPromptInput(newInput);
  }

  function handleSend() {
    const trimmedInput = promptInput.trim();

    if (trimmedInput == "" || isSendDisabled) return;

    setIsSendDisabled(true);
    setIsLoadingMessage(true);
    onPrompt({ message: trimmedInput });
    setPromptInput("");
  }

  function buildListItems(list: InteractionItem[]): ConversationListItem[] {
    if (!showInitialMessage) {
      return list.map(buildListItem).slice(1);
    }
    return list.map(buildListItem);
  }

  function buildListItem(
    interactionItem: InteractionItem,
  ): ConversationListItem {
    let icon: IconNames | undefined;
    let iconColor: IconColorNames | undefined;

    switch (interactionItem.type) {
      case messageType.REQUEST:
        icon = undefined;
        iconColor = undefined;
        break;
      case messageType.ERROR:
        icon = "alert";
        iconColor = "criticalOnSurface";
        break;
      case messageType.SYSTEM:
      case messageType.RESPONSE:
      case messageType.PARTIAL_RESPONSE:
        icon = "sparkles";
        iconColor = "success";
        break;
    }

    return {
      id: interactionItem.id,
      actions: interactionItem.actions ?? [],
      requestId: interactionItem.requestId,
      icon: icon,
      iconColor: iconColor,
      type: interactionItem.type,
      content: interactionItem.message,
      questionInterpretation: interactionItem.questionInterpretation,
      visuals: interactionItem.visuals,
      // Only add footnote if there is one.
      ...(interactionItem.footnotes && {
        footnotes: interactionItem.footnotes,
      }),
      // Actions add buttons below the message. Don't add further buttons (followup pills) when they exist.
      followups:
        !interactionItem.actions || interactionItem.actions.length === 0
          ? interactionItem.followups
          : [],
      ...(interactionItem.followups.length && {
        onClick: (prompt: string) => {
          if (isSendDisabled) return;
          setIsSendDisabled(true);
          setIsLoadingMessage(true);
          onPrompt({ message: prompt });
        },
      }),
    };
  }
};

import React from "react";
import type { AddonUpsellProps } from "~/jobber/billing/context/AddonUpsellContext/AddonUpsellContext";
import {
  AddonPurchaseModal,
  type ModalActionProps,
  type PurchaseModalButtonProps,
} from "./AddonPurchaseModal";

export interface AddonPurchaseModalLoaderProps {
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  addonUpsell?: AddonUpsellProps;
  successAction?: () => void;
  buttonProps?: PurchaseModalButtonProps;
  modalActionProps?: ModalActionProps;
  onDismiss?: () => void;
  onOpen?: () => void;
}

export function AddonPurchaseModalLoader(props: AddonPurchaseModalLoaderProps) {
  const {
    recurlyPublicKey,
    addonSetIdentifier,
    addonUpsell,
    successAction,
    onDismiss,
    buttonProps,
    modalActionProps,
    onOpen,
  } = props;

  return (
    <AddonPurchaseModal
      recurlyPublicKey={recurlyPublicKey}
      addonSetIdentifier={addonSetIdentifier}
      addonUpsell={addonUpsell}
      successAction={successAction}
      onDismiss={onDismiss}
      buttonProps={{
        buttonSize: buttonProps?.buttonSize,
        buttonText: buttonProps?.buttonText,
        buttonFullWidth: buttonProps?.buttonFullWidth,
        onClick: buttonProps?.onClick,
        buttonType: buttonProps?.buttonType,
        loading: buttonProps?.loading,
        icon: buttonProps?.icon,
      }}
      modalActionProps={{
        buttonText: modalActionProps?.buttonText,
        onClick: modalActionProps?.onClick,
      }}
      onOpen={onOpen}
    />
  );
}

import {
  Autocomplete,
  type GroupOption,
  type Option,
} from "@jobber/components/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { type ApolloClient, useApolloClient } from "@apollo/client";
import type { InputTextRef } from "@jobber/components/InputText";
import type { LineItemsAction } from "~/jobber/lineItems/hooks";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks";
import { type LineItem, mergeProductOrService } from "~/jobber/lineItems/types";
import type {
  LineItemsOptionsQueryQuery,
  ProductOrServiceOptionsFragment as ProductOrService,
} from "~/utilities/API/graphql";
import { currencyWithUnit } from "utilities/currencyWithUnit";
import { useAuthorization } from "~/utilities/contexts/authorization/useAuthorization";
import type { WorkItem } from "jobber/workItems/types";
import { truncate } from "utilities/truncate";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { PRODUCTS_SEARCH_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import { useOnMount } from "~/utilities/hooks/useOnMount/useOnMount";
import styles from "./LineItemName.module.css";
import { type FillData, FillWithLast } from "./FillWithLast";
import { AddNewProductOrServicePrompt } from "./AddNewProductOrServicePrompt";

const MAX_NAME_INPUT_LENGTH = 255;

export function LineItemName<T extends LineItem = LineItem>({
  lineItem,
  onChange,
  initialOptions,
  propertyId,
  shouldAutoFocus,
  processLineItem = li => li as T,
}: {
  lineItem: LineItem;
  propertyId?: string;
  onChange: React.Dispatch<LineItemsAction<T>>;
  initialOptions: ProductOrService[];
  shouldAutoFocus: boolean;
  processLineItem?: (lineItem: LineItem) => T;
}) {
  const { currencySymbol } = useAccount();
  const client = useApolloClient();
  const { can } = useAuthorization();
  const canViewProductsAndServices = can("view", "ProductsAndServices");

  const inputNameRef = useRef<InputTextRef>(null);

  /**
   * While rendering the LineItemName component,
   * if the line item the name is empty,
   * focus the name input
   * */
  useOnMount(() => {
    if (!lineItem.name && shouldAutoFocus) {
      inputNameRef.current?.focus();
    }
  });

  const [products, setProducts] = useState<ProductOrService[] | undefined>(
      initialOptions,
    ),
    [isCustomLineItem, setIsCustomLineItem] = useState(false),
    [inputIsFocused, setInputIsFocused] = useState(false),
    [showFillWithLast, setShowFillWithLast] = useState(false),
    [selectedProductId, setSelectedProductId] = useState<string | undefined>(
      undefined,
    );

  useEffect(() => {
    setProducts(initialOptions);
  }, [initialOptions]);

  // TODO: fix up the `handleNameChange` usage
  return (
    <div className={styles.lineItemNameContainer}>
      <Autocomplete
        ref={inputNameRef}
        name={`lineItems.${lineItem.reactKey}.lineItemSearch`}
        value={{
          label: lineItem.name,
          description: lineItem.description,
        }}
        initialOptions={mapSearchResultsToListOptions(
          initialOptions,
          currencySymbol,
        )}
        getOptions={getOptions}
        onChange={handleNameChange}
        placeholder="Name"
        onBlur={() => {
          setInputIsFocused(false);
        }}
        onFocus={() => {
          setInputIsFocused(true);
        }}
        debounce={500}
        validations={{
          required: {
            message: "Enter a line item name",
            value: true,
          },
        }}
      />
      <AddNewProductOrServicePrompt
        canViewProductsAndServices={canViewProductsAndServices}
        show={isCustomLineItem && inputIsFocused}
        lineItem={lineItem}
        currencySymbol={currencySymbol}
        onSave={async newLineItem => {
          updateLineItemWithWorkItemData(newLineItem);
          setIsCustomLineItem(false);
          // Clear search results from Apollo cache so newly created product or service shows up in autocomplete
          clearSearchCache(client);
        }}
      />
      <FillWithLast
        show={showFillWithLast}
        onClose={() => {
          setShowFillWithLast(false);
        }}
        onSelect={fillData => {
          updateLineItemWithLastJobData(fillData, lineItem, onChange);
          setShowFillWithLast(false);
        }}
        propertyId={propertyId}
        productId={selectedProductId}
      />
    </div>
  );

  async function getOptions(term: string) {
    if (term.length === 0) {
      const results = mapSearchResultsToListOptions(
        initialOptions,
        currencySymbol,
      );
      setProducts(initialOptions);
      return results;
    }
    setIsCustomLineItem(false);

    const result = await client.query<LineItemsOptionsQueryQuery>({
      query: PRODUCTS_SEARCH_QUERY,
      variables: {
        searchTerm: term,
      },
    });

    if (result.data.productsSearch.nodes.length <= 0 && term) {
      setIsCustomLineItem(true);
    }
    const results = mapSearchResultsToListOptions(
      result.data.productsSearch.nodes,
      currencySymbol,
    );
    setProducts(result.data.productsSearch.nodes);
    return results;
  }

  function handleNameChange(option?: Option) {
    if (!option || option.label.length > MAX_NAME_INPUT_LENGTH) {
      return;
    }

    const product =
      products?.filter(p => p.id === option?.value)?.[0] ??
      initialOptions?.filter(p => p.id === option?.value)?.[0];
    if (!product?.name) {
      onChange({
        type: LineItemsBulkEditActionTypes.UpdateLineItem,
        reactKey: lineItem.reactKey,
        field: "name",
        value: option.label,
      });
      return;
    }

    updateLineItemWithWorkItemData(product);

    setSelectedProductId(product.id);
    setShowFillWithLast(true);
  }

  function updateLineItemWithWorkItemData(newWorkItem: WorkItem) {
    onChange({
      type: LineItemsBulkEditActionTypes.FullUpdate,
      reactKey: lineItem.reactKey,
      value: processLineItem(mergeProductOrService(lineItem, newWorkItem)),
    });
  }
}

export function mapSearchResultsToListOptions(
  result: ProductOrService[] = [],
  currencySymbol: string,
) {
  const sections: GroupOption[] = [];
  result.forEach((workItem: ProductOrService) => {
    const formattedAmount = currencyWithUnit(
      workItem.defaultUnitCost.toString(),
      currencySymbol,
    ).format();

    const option = {
      value: workItem.id,
      label: workItem.name,
      description: handleDescription(workItem.description),
      details: formattedAmount,
    };

    const selectedSection = sections.find(
      section => section.label === workItem.category,
    );

    if (selectedSection) {
      selectedSection.options.push(option);
    } else {
      sections.push({ label: workItem.category, options: [option] });
    }
  });
  return sections;
}

function updateLineItemWithLastJobData(
  fillData: FillData,
  lineItem: LineItem,
  onChange: React.Dispatch<LineItemsAction>,
) {
  onChange({
    type: LineItemsBulkEditActionTypes.UpdateLineItem,
    reactKey: lineItem.reactKey,
    field: "unitPrice",
    value: fillData.price,
  });

  onChange({
    type: LineItemsBulkEditActionTypes.UpdateLineItem,
    reactKey: lineItem.reactKey,
    field: "description",
    value: fillData.description,
  });
}

function clearSearchCache(client: ApolloClient<unknown>) {
  client.cache.evict({ fieldName: "productsSearch" });
}

function handleDescription(description?: string) {
  if (description) {
    return truncate(description, 70);
  }
  return undefined;
}

import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalHeader: {
    id: "customFieldArchiveModal.modalHeader",
    defaultMessage: "Archive {appName} custom field",
    description: "Text to display in the modal title",
  },
  modalInitialContent: {
    id: "customFieldArchiveModal.modalInitialContent",
    defaultMessage: "Archiving this custom field means",
    description: "Initial content within the modal",
  },
  modalBoldContent: {
    id: "customFieldArchiveModal.modalBoldContent",
    defaultMessage:
      "it will not appear on any new {workObjectName} created, but will remain visible on any current or past {workObjectName}",
    description: "Bolded text within the modal",
  },
  modalEndingContent: {
    id: "customFieldArchiveModal.modalEndingContent",
    defaultMessage:
      "If you change your mind, you can unarchive this custom field to restore it. You will also be able to permanently delete it.",
    description: "Ending content within the modal",
  },
  appModalArchivedHeader: {
    id: "customFieldArchiveModal.appModalArchivedHeader",
    defaultMessage: "Disconnect {appName} to archive custom fields",
    description: "Text to display in the modal title",
  },
  appModalUnarchivedHeader: {
    id: "customFieldArchiveModal.appModalUnarchivedHeader",
    defaultMessage: "Reconnect {appName} to unarchive custom fields",
    description: "Text to display in the modal title",
  },
  appModalArchivedIntitialContent: {
    id: "customFieldArchiveModal.appModalArchivedInitialContent",
    defaultMessage:
      "To archive {appName} custom fields, disconnect the app from your Jobber account.",
    description: "Initial content within the modal",
  },
  appModalArchivedEndingContent: {
    id: "customFieldArchiveModal.appModalArchivedEndingContent",
    defaultMessage:
      "The custom fields will be archived automatically once the app is disconnected.",
    description: "Ending content within the modal",
  },
  appModalUnarchivedContent: {
    id: "customFieldArchiveModal.appModalUnarchivedContent",
    defaultMessage:
      "To unarchive {appName} custom fields, reconnect the app first.",
    description: "Content within the modal",
  },
});

import React from "react";
import type { LineItem as LineItemType } from "~/jobber/lineItems/types";
import { LineItemsBulkEditActionTypes } from "~/jobber/lineItems/hooks/lineItemsBulkEditReducer";
import type {
  Feature,
  FeatureName,
} from "~/utilities/contexts/internal/AccountContext";
import type { LineItemInputProps } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/LineItemInput/types";
import { LineItem } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/LineItemInput/LineItem";

export function workOrderBulkLineItemRenderer(
  lineItems: LineItemType[],
  features: Record<FeatureName, Feature>,
) {
  const showCosting =
    features.jobCosting.enabled && features.quoteMargins.enabled;
  const hasMultipleLineItems = lineItems.length > 1;

  return function lineItemRenderer({
    dispatch,
    lineItem,
    initialOptions,
    dragHandle,
  }: LineItemInputProps) {
    return (
      <LineItem.Container>
        <LineItem.InputContainer dragHandle={dragHandle}>
          <LineItem.InputRow>
            <LineItem.Name
              lineItem={lineItem}
              onChange={dispatch}
              initialOptions={initialOptions}
              shouldAutoFocus={hasMultipleLineItems}
              processLineItem={li => ({
                ...li,
                image: undefined,
                // break association with quote line item when selecting a new product/service
                quoteLineItemId: undefined,
              })}
            />
            <LineItem.Quantity
              quantity={lineItem.quantity}
              onChange={value => {
                dispatch({
                  type: LineItemsBulkEditActionTypes.UpdateLineItem,
                  reactKey: lineItem.reactKey,
                  field: "quantity",
                  value,
                });
              }}
              reactKey={lineItem.reactKey}
            />
            {showCosting && (
              <LineItem.UnitCost
                unitCost={lineItem.unitCost}
                onChange={value => {
                  dispatch({
                    type: LineItemsBulkEditActionTypes.UpdateLineItem,
                    reactKey: lineItem.reactKey,
                    field: "unitCost",
                    value,
                  });
                }}
                reactKey={lineItem.reactKey}
              />
            )}
            <LineItem.UnitPrice
              unitPrice={lineItem.unitPrice}
              onChange={value => {
                dispatch({
                  type: LineItemsBulkEditActionTypes.UpdateLineItem,
                  reactKey: lineItem.reactKey,
                  field: "unitPrice",
                  value,
                });
              }}
              reactKey={lineItem.reactKey}
            />
            <LineItem.TotalPrice
              totalPrice={lineItem.totalPrice}
              onChange={value => {
                dispatch({
                  type: LineItemsBulkEditActionTypes.UpdateLineItem,
                  reactKey: lineItem.reactKey,
                  field: "totalPrice",
                  value,
                });
              }}
              reactKey={lineItem.reactKey}
            />
          </LineItem.InputRow>
          <LineItem.InputRow>
            <LineItem.Description
              description={lineItem.description}
              reactKey={lineItem.reactKey}
              onChange={value => {
                dispatch({
                  type: LineItemsBulkEditActionTypes.UpdateLineItem,
                  reactKey: lineItem.reactKey,
                  field: "description",
                  value,
                });
              }}
            />
          </LineItem.InputRow>
        </LineItem.InputContainer>
        <LineItem.ActionContainer>
          <LineItem.DeleteButton
            onDelete={async () => {
              dispatch({
                type: LineItemsBulkEditActionTypes.DeleteLineItem,
                reactKey: lineItem.reactKey,
              });
            }}
            lineItem={lineItem}
          />
        </LineItem.ActionContainer>
      </LineItem.Container>
    );
  };
}

import React from "react";
import { Banner } from "@jobber/components";
import { useIntl } from "react-intl";
import { wrapperMessages } from "jobber/chat/components/UkKycRegistration/wrapperMessages";
import styles from "jobber/chat/components/UkKycRegistration/UkKycRegistration.module.css";

export function UkKycPendingState() {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.bannerWrapper}>
      <Banner type={"notice"} dismissible={false}>
        {formatMessage(wrapperMessages.pendingStateBannerMessage)}
      </Banner>
    </div>
  );
}

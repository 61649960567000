import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useCollectionQuery } from "@jobber/hooks/useCollectionQuery";
import type { DeepPartial } from "utility-types";
import { useAiReceptionist } from "jobber/chat/components/ChatDrawer/Chat/AiReceptionistToggle/useAiReceptionist";
import { useCurrentUser } from "jobber/settings/users/hooks/useCurrentUser";
import type {
  ConversationMessageSubscription,
  ConversationMessagesQuery,
  MessageStatusEnum,
  Scalars,
  User,
} from "~/utilities/API/graphql";
import {
  CONVERSATION_MESSAGES_QUERY,
  CONVERSATION_MESSAGE_SUBSCRIPTION,
  READ_CONVERSATION_MUTATION,
} from "jobber/chat/components/ChatDrawer/Chat/Chat.graphql";
import { ConversationsNotFoundEmptyState } from "jobber/chat/components/ChatDrawer/ChatList/EmptyState/ConversationsNotFoundEmptyState";
import { Chat } from "jobber/chat/components/ChatDrawer/Chat/Chat";
import { Rollbar } from "~/utilities/errors/Rollbar";

interface ChatWrapperProps {
  conversationId: string;
}

export type Message = NonNullable<
  NonNullable<ConversationMessagesQuery["conversation"]>["messages"]["edges"]
>[0]["node"];

export function processMessages(
  data: ConversationMessagesQuery | undefined,
  listItems: Message[],
): Message[] {
  let tempMessages = listItems.filter(item => item.id == "temp-sending");

  const loadedMessages =
    data?.conversation?.messages.edges?.map(edge => {
      tempMessages = tempMessages.filter(
        item =>
          item.clientGeneratedId &&
          item.clientGeneratedId !== edge.node.clientGeneratedId,
      );
      return edge.node;
    }) || [];
  return [...loadedMessages, ...tempMessages].sort((m1, m2) => {
    return new Date(m1.timestamp) < new Date(m2.timestamp) ? 1 : -1;
  });
}

export function addSendingMessage(
  message: string,
  clientGeneratedId: Scalars["Uuid"]["input"],
  listItems: Message[],
  currentUser: DeepPartial<User>,
  state: MessageStatusEnum,
) {
  const tempMessage = {
    __typename: "TextMessage",
    commType: "OUTBOUND",
    direction: "OUTBOUND",
    subject: "",
    id: "temp-sending",
    content: message,
    clientGeneratedId: clientGeneratedId,
    status: state,
    internalContactObject: {
      __typename: "User",
      id: currentUser.id,
      name: {
        __typename: "Name",
        full: currentUser.fullName,
        first: currentUser.fullName,
      },
    },
    timestamp: new Date().toISOString(),
    files: { __typename: "BaseFileInterfaceConnection", nodes: [] },
  } as Message;
  listItems = listItems.filter(
    i => i.clientGeneratedId !== tempMessage.clientGeneratedId,
  );
  return [tempMessage, ...listItems];
}

// eslint-disable-next-line max-statements
export function ChatWrapper({ conversationId }: ChatWrapperProps) {
  const [markConversationRead] = useMutation(READ_CONVERSATION_MUTATION);
  const { currentUser } = useCurrentUser(["fullName"]);
  const {
    aiReceptionistSmsState,
    aiReceptionistLoading,
    toggleAiReceptionist,
  } = useAiReceptionist(conversationId);

  // Called when subscription gets a response, meaning a new message came in.
  // When responding to the subscription we mark the conversation as read because of the new message.
  function nodeByPath(edges: ConversationMessageSubscription) {
    markAsRead();
    return edges?.conversationMessage.message;
  }

  function afterInput(
    message: string,
    clientGeneratedId: Scalars["Uuid"]["input"],
    status: MessageStatusEnum,
  ) {
    setListItems(
      addSendingMessage(
        message,
        clientGeneratedId,
        listItems,
        currentUser,
        status,
      ),
    );
  }

  const { data, error, nextPage, loadingInitialContent } = useCollectionQuery<
    ConversationMessagesQuery,
    ConversationMessageSubscription
  >({
    query: CONVERSATION_MESSAGES_QUERY,
    queryOptions: {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        id: conversationId,
      },
    },
    getCollectionByPath: result => result?.conversation?.messages,
    subscription: {
      document: CONVERSATION_MESSAGE_SUBSCRIPTION,
      options: {
        variables: {
          conversationId,
        },
      },
      getNodeByPath: nodeByPath,
    },
  });

  if (error) {
    Rollbar.EXECUTE("Error Fetching More Chat Messages", error);
  }
  const [listItems, setListItems] = useState(
    data?.conversation?.messages.edges?.map(edge => edge.node) || [],
  );

  useEffect(() => {
    setListItems(processMessages(data, listItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loadingInitialContent || aiReceptionistLoading) {
    return (
      <div className={"row collapse align-center u-paddingNone"}>
        <div className={"columns spinner spinner--inline"} />
      </div>
    );
  }

  if (!data?.conversation?.messages) {
    return <ConversationsNotFoundEmptyState />;
  }

  return (
    <Chat
      messages={listItems}
      hasNextPage={data?.conversation?.messages?.pageInfo?.hasNextPage}
      getMoreMessages={nextPage}
      markConversationAsRead={markAsRead}
      afterInput={afterInput}
      aiReceptionistSmsState={aiReceptionistSmsState}
      toggleAiReceptionist={toggleAiReceptionist}
    />
  );

  function markAsRead() {
    markConversationRead({
      variables: {
        id: conversationId,
      },
    }).catch((err: Error) => {
      Rollbar.EXECUTE("Error Marking Conversation as Read", err);
    });
  }
}

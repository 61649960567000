import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { CLICKED_CHOOSE_PLAN_EVENT } from "jobber/billing/utils/trackInteractions";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "./messages";

export interface CheckoutLinkStyleProps {
  buttonType?: React.ComponentProps<typeof Button>["type"];
  buttonLabel: string;
  buttonFullWidth?: boolean;
  buttonSize?: "small" | "base" | "large";
  buttonVariation?: "learning" | "work" | "destructive" | "subtle";
}

interface LegacyCheckoutParams {
  planSetIdentifier: string;
  tierName: string;
  isAnnual?: boolean;
  offer?: string;
}

export interface CheckoutLinkProps {
  planCode: string;
  tierDisplayName: string;
  buttonStyleProps: CheckoutLinkStyleProps;
  legacyCheckoutParams: LegacyCheckoutParams;
  source?: string;
  onClick?: () => void;
  preventDefaultTracking?: boolean;
}

const encodedPlanCode = (planCode: string) => btoa(planCode);
const checkoutUrl = (planCode: string) =>
  `/checkout?pc=${encodedPlanCode(planCode)}`;

export function PrimaryActionButton(props: CheckoutLinkProps) {
  return (
    <IntlProvider>
      <PrimaryActionButtonInternal {...props} />
    </IntlProvider>
  );
}

function PrimaryActionButtonInternal({
  planCode,
  tierDisplayName,
  buttonStyleProps,
  legacyCheckoutParams,
  source,
  onClick,
  preventDefaultTracking = false,
}: CheckoutLinkProps) {
  const {
    buttonType,
    buttonLabel,
    buttonFullWidth,
    buttonSize,
    buttonVariation,
  } = buttonStyleProps;
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const legacyCheckoutURL = getLegacyCheckoutURL(legacyCheckoutParams);
  const ariaLabel = formatMessage(messages.choosePlan, {
    plan: tierDisplayName,
  });

  return (
    <Button
      size={buttonSize ?? "large"}
      fullWidth={buttonFullWidth}
      ariaLabel={ariaLabel}
      label={buttonLabel}
      onClick={onChoosePlanClick}
      type={buttonType}
      loading={loading}
      variation={buttonVariation}
    />
  );

  async function onChoosePlanClick() {
    setLoading(true);

    if (onClick) {
      onClick();
    }

    !preventDefaultTracking && handleOnClickTracking(tierDisplayName, source);
    const useFullCheckoutPage = await shouldUseFullPageCheckout();

    if (useFullCheckoutPage === true) {
      return (window.location.href = checkoutUrl(planCode));
    }

    await $.getScript(legacyCheckoutURL).done(() => {
      setLoading(false);
    });
  }
}

export function DropdownItem({
  planCode,
  tierDisplayName,
  buttonStyleProps,
  legacyCheckoutParams,
  source,
}: CheckoutLinkProps) {
  const { buttonLabel } = buttonStyleProps;
  const legacyCheckoutURL = getLegacyCheckoutURL(legacyCheckoutParams);

  return (
    <button
      className="dropdown-item"
      onClick={onDropdownItemClick}
      type="button"
      aria-label={buttonLabel}
    >
      {buttonLabel}
    </button>
  );

  async function onDropdownItemClick() {
    handleOnClickTracking(tierDisplayName, source);

    const useFullCheckoutPage = await shouldUseFullPageCheckout();

    if (useFullCheckoutPage === true) {
      return (window.location.href = checkoutUrl(planCode));
    }

    await $.getScript(legacyCheckoutURL);
  }
}

function getLegacyCheckoutURL(legacyCheckoutParams: LegacyCheckoutParams) {
  const { planSetIdentifier, tierName, isAnnual, offer } = legacyCheckoutParams;

  return `/accounts/billing_info/edit.dialog?plan_set_identifier=${planSetIdentifier}&tier_name=${tierName}${
    isAnnual !== undefined ? `&is_annual=${isAnnual}` : ""
  }&is_choosing_plan=true${offer !== undefined ? `&offer=${offer}` : ""}`;
}

async function shouldUseFullPageCheckout() {
  const response = await fetch("/checkout/use_full_page_checkout");

  if (response.ok) {
    const data = await response.json();

    return data.treatment;
  }
  return false;
}

function handleOnClickTracking(tierDisplayName: string, source?: string) {
  const lowercaseTierDisplayName = tierDisplayName.toLowerCase();
  Amplitude.TRACK_EVENT(CLICKED_CHOOSE_PLAN_EVENT, {
    plan: lowercaseTierDisplayName,
    source: source || `pricing_${lowercaseTierDisplayName}_plan`,
  });
}
